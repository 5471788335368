import React from 'react';
import { navigate } from 'gatsby'
import { Box,  Image } from 'grommet';
import { Button } from '@appkit4/react-components/button';
import SidebarCSA from '../../components/SidebarCSA'

export default function CSARobocaller() {
  const onClickButton_CSAMyLearning = () => {
    navigate ('/cloud-security-architect')
  }
  const onClickButton_CSAExplore = () => {
    navigate ('/cloud-security-architect/csa-explore')
  } 
  return (
    <Box
      background={{
      color: '#ffb600'
    }} 
      width= {'100vw'}
      height= {'91vh'}
      direction={'row'}
    >
      <Box pad={{top: '40px', horizontal: "large"}} 
      align={'top'}
      direction={'row'}
      alignContent="between" 
      gap="medium">
        <SidebarCSA />
        <Box style={{marginTop: '10px', overflow: 'scroll', borderRadius: '.5em', maxHeight: '90%', maxWidth: '60vw'}}>
          <Box style={{position: 'relative'}}>
            <Image src="https://images.ctfassets.net/fwgo2d14oeir/3CaBe5fVALlwrYZa66KKZE/783986a8c36e53c14fb0f1a699e12730/my-learning-CSA-robocaller.png" fill="horizontal" style={{ position: 'relative', borderRadius: '.5em'}} useMap="#discover-new" />
            <Button style={{width: '80px', position: 'absolute', top: '12px', left: '20%'}} kind='text' onClick={onClickButton_CSAMyLearning} >&nbsp; &nbsp;<div className='beacon'></div></Button>
            <Button style={{width: '80px', position: 'absolute', top: '12px', left: '30%'}} kind='text' onClick={onClickButton_CSAExplore} >&nbsp; &nbsp;<div className='beacon'></div></Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}